<template>
  <div class="container page__inner">
    <div class="page__content--wider">
      <div class="page__head2">
        <div
          class="page__head__container align-items-center justify-content-between"
        >
          <div class="page__head__text">
            <h3
              class="page__head3__title"
              v-if="fetchModel.customerStatus == null"
            >
              All customers
            </h3>
            <h3
              class="page__head3__title"
              v-if="fetchModel.customerStatus != null"
            >
              Customers: {{ fetchModel.customerStatus }}
            </h3>
          </div>

          <div class="page__head__actions">
            <div class="d-flex">
              <!--<button
                type="button"
                data-target="#editColumnModal"
                data-toggle="modal"
                class="mx-2 button page__body__header__button button--grey text--capital"
              >
                <span class="d-inline-flex mr-2">edit columns</span>
              </button>-->

              <!--<div class="align-self-center mx-2 dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  custom columns
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    class="dropdown-item text--capital"
                    data-target="#addNewCustomModal"
                    data-toggle="modal"
                    >new column preset</a
                  >
                  <a class="dropdown-item text--capital">custom customs</a>
                </div>
              </div>-->

              <div class="align-self-center mx-2 dropdown">
                <button
                  type="button"
                  class="w-100 button page__body__header__button button--grey text--capital"
                  @click="toggleFilterModal"
                >
                  <span class="d-inline-flex mr-2">filter</span>
                </button>
              </div>

              <!--<div class="align-self-center mx-2 dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  all branches
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item text--capital">branch 1</a>
                  <a class="dropdown-item text--capital">branch 2</a>
                </div>
              </div>-->

              <div class="align-self-center mx-2 dropdown">
                <button
                  class="button button--grey text--capital text--small d-flex align-items-center"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  export
                  <span class="dropdown__icon">
                    <ion-icon name="caret-down-outline"></ion-icon>
                  </span>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a
                    href="javascript:void(0)"
                    class="dropdown-item text--capital"
                    v-if="userCanExportToPDF"
                    @click="pdfDownload"
                    >PDF</a
                  >
                  <a
                    href="javascript:void(0)"
                    class="dropdown-item text--capital"
                    v-if="userCanExportToExcel"
                    @click="processDownload"
                    >excel</a
                  >
                  <!-- <download-excel
                    class="dropdown-item text--capital"
                    style="cursor: pointer"
                    :data="download"
                    :fields="json_fields"
                    name="customers.xls"
                  >
                    excel
                  </download-excel> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; width: 50%; font-size: 14px" v-if="filterText">
        <div style="width: 80%">
          {{ filterText }}
        </div>
        <div style="display: flex; width: 10%">
          <span style="cursor: pointer; font-size: 17px" @click="editFilter">
            <i style="padding-right: 5px" class="bi bi-pencil-square"></i>
          </span>
          <span style="cursor: pointer; font-size: 17px" @click="deleteFilter">
            <i style="padding-left: 5px" class="bi bi-x-circle-fill"></i>
          </span>
        </div>
      </div>
      <div class="page__body">
        <table class="customtable w-100">
          <thead>
            <tr>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                full name
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                ID
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                Branch
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                customer status
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                account officer
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                total balance
              </th>
              <th
                scope="col"
                class="table__header__text table__text text--capital"
                style="width: 15%"
              >
                date modified
              </th>
            </tr>
          </thead>
          <tbody v-if="userIsNotAuthorized">
            <tr>
              <td colspan="12">
                <div
                  class="d-flex flex-column align-items-center justify-content-center p5"
                >
                  <img src="@/assets/img/empty.svg" alt="delete" srcset="" />

                  <span
                    class="d-block text--danger text--600 text--medium mt-3 mb-5 text-center"
                    >{{ customerError }}</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="7">
                <div
                  class="d-flex align-items-center justify-content-center p5"
                >
                  <div
                    class="spinner-border"
                    style="width: 1rem; height: 1rem"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span
                    class="text--black text--600 text--medium d-inline-flex ml-4"
                    >Please wait</span
                  >
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="item in allCustomers" v-bind:key="item.customerKey">
              <td>
                <router-link
                  tag="a"
                  :to="{
                    name: 'customer_overview',
                    params: { id: item.customerKey },
                  }"
                  class="table__body__text table__text text--capital text--primary"
                  style="word-break: break-word; padding-right: 10px"
                >
                  {{ item.customerFirstName }}
                  {{ item.customerMiddleName }}
                  {{ item.customerLastName }}</router-link
                >
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  item.uniqueCustomerId
                }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  item.branchAssigned
                }}</span>
              </td>
              <td>
                <span
                  v-if="item.customerStatus === 'Rejected'"
                  class="table__body__text table__text text--capital text--danger"
                  >{{ item.customerStatus }}</span
                >
                <span
                  v-if="item.customerStatus === 'Inactive'"
                  class="table__body__text table__text text--capital text--danger"
                  >{{ item.customerStatus }}</span
                >
                <span
                  v-if="item.customerStatus === 'Active'"
                  class="table__body__text table__text text--capital text--success"
                  >{{ item.customerStatus }}</span
                >
                <span
                  v-if="item.customerStatus === 'Pending approval'"
                  class="table__body__text table__text text--capital text--primary"
                  >{{ item.customerStatus }}</span
                >
              </td>
              <td>
                <span class="table__body__text table__text text--capital">
                  {{ item.accountOfficer ? item.accountOfficer : "" }}
                </span>
              </td>
              <td>
                <span class="table__body__text table__text">{{
                  item.totalBalance | currency
                }}</span>
              </td>
              <td>
                <span class="table__body__text table__text text--capital">{{
                  item.lastModifiedDate | moment("Do-MMM-YYYY")
                }}</span>
              </td>
              <!-- <td>
                <div class="align-self-center dropdown">
                  <button
                    class="button button--grey text--capital text--small d-flex align-items-center"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    action
                    <span class="dropdown__icon">
                      <ion-icon name="caret-down-outline"></ion-icon>
                    </span>
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#editCustomerModal"
                      v-on:click="editCustomer(item)"
                      >edit</a
                    >
                     <a
                      class="dropdown-item text--capital"
                      data-toggle="modal"
                      data-target="#customerStatusModal"
                      v-on:click="changeStatus(item.customerKey)"
                      >change status</a
                    >
                  </div>
                </div>
              </td> -->
            </tr>
          </tbody>
        </table>

        <div class="pagination" v-if="!userIsNotAuthorized">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="text--capital text--regular pagination__text">
              showing
              <span class="d-inline-block text--primary mr-3">
                <label for="">
                  <select
                    v-model.number="fetchModel.pageSize"
                    class="select select--lg select--page"
                    @change="changePageSize(fetchModel.pageSize)"
                  >
                    <option
                      v-for="psize in pagesizes"
                      :key="psize.id"
                      :id="psize.id"
                      :selected="fetchModel.pageSize"
                    >
                      {{ psize.size }}
                    </option>
                  </select>
                </label>
              </span>

              <!--<span
                class="d-inline-block text--primary"
                v-if="paging.totalCount == 0"
                >0</span
              >-->
              <span class="d-inline-block text--primary">{{
                paging.pageIndex
              }}</span>
              to
              <span class="d-inline-block text--primary">{{
                paging.totalPages
              }}</span>
              of
              <span>{{ paging.totalCount }}</span>
            </div>

            <div
              class="pagination__buttons d-flex align-items-center justify-content-between"
            >
              <button
                class="button button--sm text--regular"
                v-if="paging.hasPreviousPage"
                @click="stepPage('previous')"
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
              <div
                v-if="!isLargeSizePaged"
                class="d-flex align-items-center pagination__buttons"
              >
                <button
                  class="button text--regular button--sm ml-2"
                  v-for="num in paging.totalPages"
                  :key="num"
                  :class="{
                    'button--primary text--white':
                      paging.pageIndex === num || paging.currentPage === num,
                  }"
                  :disabled="
                    paging.pageIndex === num || paging.currentPage === num
                  "
                  @click="changePage(num)"
                >
                  {{ num }}
                </button>
              </div>

              <div v-else class="text--capital text--regular pagination__text">
                Page
                <span class="d-inline-block text--primary">{{
                  paging.currentPage
                }}</span>
                of
                <span class="d-inline-block text--primary">{{
                  paging.totalPages
                }}</span>
                from
                <span class="d-inline-block text--primary">{{
                  paging.totalCount
                }}</span>
                Records
              </div>

              <button
                class="button text--regular button--sm ml-2"
                v-if="paging.hasNextPage"
                @click="stepPage('next')"
              >
                <ion-icon name="chevron-forward-outline"></ion-icon>
              </button>
            </div>
          </div>
        </div>
        <!-- <div class="pagination">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="text--capital text--regular pagination__text">
            showing <span class="d-inline-block text--primary">1</span> to
            <span class="d-inline-block text--primary">10</span> of
            <span>1200</span>
          </div>
          <div
            class="pagination__buttons d-flex align-items-center justify-content-between"
          >
            <button class="button button--sm text--regular">
              <ion-icon name="chevron-back-outline"></ion-icon>
            </button>
            <button
              class="button text--regular button--primary text--white text-white button--sm ml-2"
            >
              1
            </button>
            <button class="button text--regular button--sm ml-2">2</button>
            <button class="button text--regular button--sm ml-2">3</button>
            <button class="button text--regular button--sm ml-2">4</button>
            <button class="button text--regular button--sm ml-2">
              <ion-icon name="chevron-forward-outline"></ion-icon>
            </button>
          </div>
        </div>
      </div> -->
      </div>

      <div
        class="modal modal__right"
        id="editCustomerModal"
        tabindex=""
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__head">
              <div class="modal__head__icon" v-show="!isSaving">
                <a
                  data-dismiss="modal"
                  id="editCustomerModalClose"
                  class="modal__close modal__close--black"
                >
                  <ion-icon name="arrow-back-outline"></ion-icon>
                </a>
              </div>
              <h5 class="modal__head__title text--capital">
                Updating a Customer
              </h5>
            </div>
            <div class="modal__body">
              <form class="form" @submit="saveEditForm">
                <ul id="accordion" class="mb5">
                  <div class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#ccgeneral"
                      aria-expanded="false"
                      aria-controls="ccgeneral"
                    >
                      <h5
                        class="w-100 mb-0 d-flex align-items-center justify-content-between"
                      >
                        General
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="ccgeneral"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">First Name</label>
                              <input
                                type="text"
                                class="input input--right form__input form__input--lg"
                                placeholder="Enter First Name"
                                v-model="editForm.customerFirstName"
                                v-bind:class="{
                                  'input-required': err.customerFirstName,
                                }"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Middle Name</label>
                              <input
                                type="text"
                                class="input input--right form__input form__input--lg"
                                placeholder="Enter Middle Name"
                                v-model="editForm.customerMiddleName"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Last Name</label>
                              <input
                                type="text"
                                class="input input--right form__input form__input--lg"
                                placeholder="Enter Last Name"
                                v-model="editForm.customerLastName"
                                v-bind:class="{
                                  'input-required': err.customerLastName,
                                }"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Customer Type</label>
                              <select
                                class="select--lg w-100"
                                v-model="editForm.customerTypesKey"
                                required
                              >
                                <option disabled value="null">
                                  Select Customer type
                                </option>
                                <option
                                  v-for="item in customerClientTypes"
                                  v-bind:key="item.customerTypeKey"
                                  v-bind:value="item.customerTypeKey"
                                >
                                  {{ item.clientTypeName }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">ID</label>
                              <input
                                type="text"
                                class="input input--right form__input form__input--lg"
                                placeholder="Enter ID"
                                v-model="editForm.uniqueCustomerId"
                                v-bind:class="{
                                  'input-required': err.uniqueCustomerId,
                                }"
                              />
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">BVN</label>
                              <input
                                type="text"
                                class="input input--right form__input form__input--lg"
                                placeholder="Enter Bvn"
                                v-model="editForm.bvn"
                                v-bind:class="{
                                  'input-required': err.bvn,
                                }"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#ccdetails"
                      aria-expanded="false"
                      aria-controls="ccdetails"
                    >
                      <h5
                        class="w-100 mb-0 d-flex align-items-center justify-content-between"
                      >
                        Details
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="ccdetails"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Date of Birth</label>
                              <input
                                type="date"
                                class="input input--right form__input form__input--lg"
                                placeholder="Enter Date of Birth"
                                v-model="editForm.customerBirthDate"
                                v-bind:class="{
                                  'input-required': err.customerBirthDate,
                                }"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Gender</label>
                              <select
                                class="select select--lg"
                                v-model="editForm.customerGender"
                                v-bind:class="{
                                  'input-required': err.customerGender,
                                }"
                              >
                                <option value="null" disabled>
                                  Select Gender
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Mobile Phone</label>
                              <input
                                type="text"
                                class="input input--right form__input form__input--lg"
                                placeholder="Enter Mobile Phone"
                                v-model="editForm.customerMobilePhone1"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Home Phone</label>
                              <input
                                type="text"
                                class="input input--right form__input form__input--lg"
                                placeholder=""
                                v-model="editForm.customerHomePhone"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Email address</label>
                              <input
                                type="text"
                                class="input input--right form__input form__input--lg"
                                placeholder="Enter email address"
                                v-model="editForm.customerEmailAddress"
                                v-bind:class="{
                                  'input-required': err.customerEmailAddress,
                                }"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Mobile Phone2</label>
                              <input
                                type="text"
                                class="input input--right form__input form__input--lg"
                                placeholder="Enter Mobile Phone2"
                                v-model="editForm.customerMobilePhone2"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label"
                                >Preferred Language</label
                              >
                              <select
                                class="select select--lg"
                                v-model="editForm.preferredLanguage"
                              >
                                <option value="null" disabled>
                                  Select Language
                                </option>
                                <option value="English">English</option>
                                <option value="French">French</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#customerIdDocs"
                      aria-expanded="false"
                      aria-controls="customerIdDocs"
                    >
                      <h5
                        class="w-100 mb-0 d-flex align-items-center justify-content-between"
                      >
                        Identification Documents
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="customerIdDocs"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div
                        class="row"
                        v-for="(
                          idTemp, index
                        ) in editForm.identificationDocuments"
                        v-bind:key="
                          idTemp.identificationDocumentTemplateKey + '' + index
                        "
                      >
                        <div class="col-md-6">
                          Document Type: {{ idTemp.documentType }}
                        </div>
                        <div class="col-md-6">
                          Issuing Authority: {{ idTemp.issuingAuthority }}
                        </div>
                        <div class="col-md-6">
                          <div class="form__item">
                            <label>Document ID</label>
                            <input
                              type="text"
                              v-model="idTemp.documentId"
                              class="input form__input form__input--lg"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form__item">
                            <label>Valid Until</label>
                            <input
                              type="date"
                              v-model="idTemp.validUntil"
                              class="input form__input form__input--lg"
                            />
                          </div>
                        </div>

                        <div v-if="idTemp.allowAttachments" class="col-12">
                          <div class="col-md-12">
                            <div class="form__item form__item_dottedbg">
                              <div class="p-5 text--center position-relative">
                                <input
                                  :id="`attachment-${index}`"
                                  type="file"
                                  class="input form__input--lg"
                                  accept="*"
                                  ref="myFiles"
                                  @change="onFileChange(index)"
                                  hidden
                                />

                                <button
                                  class="my-2 button button--black text--500 text--white text--small text--capital"
                                  @click.prevent="
                                    callFileUpload(`attachment-${index}`)
                                  "
                                >
                                  Choose File
                                </button>
                              </div>
                              <ul class="list-group">
                                <li class="list-group-item">
                                  {{ idTemp.filename }} ({{
                                    idTemp.fileSize | kb
                                  }}
                                  kb)
                                </li>
                              </ul>
                            </div>
                          </div>

                          <div
                            class="row align-items-center col-md-12"
                            v-if="idTemp.showDoc"
                          >
                            <div class="col-10">
                              <div class="form__item">
                                <label
                                  @click.prevent="
                                    downloadAttachment(
                                      index,
                                      idTemp.attachments[0].documentDescription
                                    )
                                  "
                                  class="download-attach"
                                  >{{
                                    idTemp.attachments[0]
                                      .documentOriginalFileName
                                  }}</label
                                >
                              </div>
                            </div>
                            <div class="col-2">
                              <div class="form__item">
                                <button
                                  class="button page__body__header__button modal__confirm__button--delete button--sm text--black w-100 text--capital"
                                  @click.prevent="
                                    deleteAttachment(
                                      index,
                                      idTemp.attachments[0].documentDescription
                                    )
                                  "
                                >
                                  <span>X</span>
                                </button>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="form__item">
                              <button
                                :disabled="loading"
                                class="my-2 button button--black text--500 text--white text--small text--capital"
                                @click.prevent="uploadFile(index)"
                              >
                                {{ loading ? "Uploading..." : "Upload File" }}
                              </button>
                            </div>
                          </div>
                        </div>

                        <div class="form__item">
                          <button
                            class="button page__body__header__button ml-auto button--grey text--capital"
                            type="button"
                            @click="idTemplateRemove(index)"
                          >
                            Remove Identification Document
                            <i class="ki ki-plus icon-sm ml-3"> </i>
                          </button>
                        </div>
                      </div>
                      <div class="pt-5">
                        <div class="form__item">
                          <label class="form__label">Select Template</label>
                          <select
                            class="select select--lg"
                            v-model="editForm.selectedIdTemplate"
                          >
                            <option :value="null" disabled selected>
                              Select ID Doc
                            </option>
                            <option
                              v-for="item in idTemplates"
                              v-bind:key="
                                item.identificationDocumentTemplateKey
                              "
                              v-bind:value="
                                item.identificationDocumentTemplateKey
                              "
                            >
                              {{ item.documentType }}
                            </option>
                          </select>
                        </div>
                        <div class="form__item">
                          <button
                            class="button page__body__header__button ml-auto button--grey text--capital"
                            type="button"
                            @click="addIdTemplateToForm()"
                          >
                            Add Identification Document
                            <i class="ki ki-plus icon-sm ml-3"> </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#ccassociated"
                      aria-expanded="false"
                      aria-controls="ccassociated"
                    >
                      <h5
                        class="w-100 mb-0 d-flex align-items-center justify-content-between"
                      >
                        Associated
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="ccassociated"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Branch</label>
                              <select
                                class="select--lg w-100"
                                v-model="editForm.assignedBranchKey"
                                v-bind:class="{
                                  'input-required': err.assignedBranchKey,
                                }"
                              >
                                <option disabled value="null">
                                  Select branch
                                </option>
                                <option
                                  v-for="item in allBranches"
                                  v-bind:key="item.branchKey"
                                  v-bind:value="item.branchKey"
                                >
                                  {{ item.branchName }} ({{ item.branchId }})
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Center</label>
                              <select
                                class="select--lg w-100"
                                v-model="editForm.assignedCentreKey"
                                v-bind:class="{
                                  'input-required': err.assignedCentreKey,
                                }"
                              >
                                <option disabled value="null">
                                  Select Center
                                </option>
                                <option
                                  v-for="item in allCentres"
                                  v-bind:key="item.centreKey"
                                  :value="item.centreKey"
                                >
                                  {{ item.centreName }} ({{ item.centreId }})
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-6">
                            <div class="form__item">
                              <label class="form__label">Account Officer</label>
                              <select
                                class="select--lg w-100"
                                v-model="editForm.assignedUserKey"
                                v-bind:class="{
                                  'input-required': err.assignedUserKey,
                                }"
                              >
                                <option disabled>Select Account Officer</option>
                                <option
                                  value="8eec0ada011f4f60a161312c3ae4db95"
                                >
                                  Test User
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="modal__accordion"
                    v-for="(set, index) in customFields"
                    :key="set.customFieldSetKey"
                  >
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      :data-target="`#ccSet${index}`"
                      aria-expanded="false"
                      aria-controls="ccprofileNote"
                    >
                      <h5
                        class="w-100 mb-0 d-flex align-items-center justify-content-between"
                      >
                        {{ set.customFieldSetName }}
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      :id="`ccSet${index}`"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div class="row">
                          <div
                            class="col-6"
                            v-for="form in set.customFields"
                            :key="form.customFieldKey"
                          >
                            <div class="form__item">
                              <label class="form__label">{{
                                form.customFieldName
                              }}</label>
                              <input
                                :type="
                                  form.dataType === 'Number'
                                    ? 'number'
                                    : form.dataType === 'Date'
                                    ? 'date'
                                    : form.dataType === 'Checkbox'
                                    ? 'checkbox'
                                    : 'text'
                                "
                                v-if="form.dataType !== 'Selection'"
                                :id="form.customFieldKey"
                                class="input input--right form__input form__input--lg"
                                :placeholder="`Enter ${form.customFieldName}`"
                              />
                              <select
                                class="select--lg w-100"
                                v-if="form.dataType === 'Selection'"
                                :id="form.customFieldKey"
                              >
                                <option disabled selected value="null">
                                  Select {{ form.customFieldName }}
                                </option>
                                <option
                                  v-for="opt in form.customFieldSelections"
                                  :key="opt.customFieldSelectionKey"
                                  :value="opt.value"
                                >
                                  {{ opt.value }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal__accordion">
                    <li
                      data-toggle="collapse"
                      class="sidebar-right__item modal__accordion--toggle"
                      data-target="#ccprofileNote"
                      aria-expanded="false"
                      aria-controls="ccprofileNote"
                    >
                      <h5
                        class="w-100 mb-0 d-flex align-items-center justify-content-between"
                      >
                        Profile Note
                        <ion-icon name="chevron-down-outline"></ion-icon>
                      </h5>
                    </li>
                    <div
                      id="ccprofileNote"
                      class="collapse"
                      data-parent="#accordion"
                    >
                      <div class="pt-5">
                        <div class="form__item">
                          <textarea
                            cols="5"
                            rows="5"
                            class="input form__input"
                            placeholder="Profile Note"
                            v-model="editForm.customerNotes"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </ul>
                <div class="form__item">
                  <div class="form__action">
                    <button
                      type="submit"
                      class="button form__button form__button--lg w-30 save-changes"
                      v-if="!isSaving"
                    >
                      Save Customer
                    </button>

                    <!-- loader button -->
                    <a
                      role="button"
                      class="button form__button form__button--lg w-25 save-changes"
                      v-if="isSaving"
                      :disabled="true"
                    >
                      <div
                        class="spinner-border text-light"
                        role="status"
                      ></div>
                    </a>
                    <a
                      data-dismiss="modal"
                      role="button"
                      class="modal__close form__action__text form__action__text--danger text--capital ml6"
                      v-if="!isSaving"
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal modal__right"
        id="customerStatusModal"
        tabindex=""
        role="dialog"
        aria-labelledby="modalLabel"
        aria-hidden="true"
      >
        <div class="modal__dialog" role="document">
          <div class="modal__content">
            <div class="modal__head">
              <div class="modal__head__icon" v-show="!isSaving">
                <a
                  data-dismiss="modal"
                  id="customerStatusModalClose"
                  class="modal__close modal__close--black"
                >
                  <ion-icon name="arrow-back-outline"></ion-icon>
                </a>
              </div>
              <h5 class="modal__head__title text--capital">
                Update Customer Status
              </h5>
            </div>
            <div
              class="spinner-border text-light"
              role="status"
              v-if="customer != null"
            ></div>
            <div class="modal__body" v-if="customer != null">
              <form class="form" @submit="updateCustomerStatusForm">
                <div class="form-row">
                  <div class="col-md-6 form__item">
                    <div class="form-group">
                      <label class="form__label">Name</label>
                      <input
                        class="input form__input w-100"
                        type="text"
                        readonly
                        :value="
                          customer.customerFirstName +
                          ' ' +
                          customer.customerLastName
                        "
                      />
                    </div>
                  </div>
                  <div class="col-md-6 form__item">
                    <div class="form-group">
                      <label class="form__label">Unique ID</label>
                      <input
                        class="input form__input w-100"
                        type="text"
                        readonly
                        :value="customer.uniqueCustomerId"
                      />
                    </div>
                  </div>
                  <div class="col-md-6 form__item">
                    <div class="form-group">
                      <label class="form__label">Status</label>
                      <select
                        class="select--lg w-100"
                        v-model="customerStatusForm.customerStatus"
                      >
                        <option
                          v-for="status in customerStatuses"
                          v-bind:key="status.val"
                          :value="status.val"
                        >
                          {{ status.text }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form__item">
                  <div class="form__action">
                    <button
                      type="submit"
                      class="button form__button form__button--lg w-30 save-changes"
                      v-if="!isSaving"
                    >
                      Update
                    </button>

                    <a
                      role="button"
                      class="button form__button form__button--lg w-25 save-changes"
                      v-if="isSaving"
                      :disabled="true"
                    >
                      <div
                        class="spinner-border text-light"
                        role="status"
                      ></div>
                    </a>
                    <a
                      data-dismiss="modal"
                      role="button"
                      class="modal__close form__action__text form__action__text--danger text--capital ml6"
                      v-if="!isSaving"
                    >
                      cancel
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--ALERT NOTIFICATION-->
    <div class="toaster show">
      <div
        class="toaster__container"
        v-if="alert.show"
        v-bind:class="{
          'toaster--success': alert.status === 'success',
          'toaster--error': alert.status === 'fail',
          'toaster--warn': alert.status === 'warn',
        }"
      >
        <div class="text-right">
          <span class="fa fa-times" @click="alert.show = false"></span>
        </div>
        <div>
          <span class="d-block text--small text--600 text--white">{{
            alert.title
          }}</span>
          <span class="d-block text--tiny text--400 text--white">{{
            alert.description
          }}</span>
        </div>
      </div>
    </div>

    <CreateFilter
      :show="showFilterModal"
      @close="toggleFilterModal"
      @send-activate-filter="sendFilter"
      :filterEditValue="filterEdit"
      :showEditedValue="showEditedValue"
      @reload="reloadFilter"
      @alertError="alertError"
      :filterHeaderName="'Customer'"
      filterCategory="customer"
    />
  </div>
</template>

<script>
import Vue from "vue";
// import JsonExcel from "vue-json-excel";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

import branchesService from "@/core/services/organization-service/branches.service";
import centresService from "@/core/services/organization-service/centres.service";
import customerService from "@/core/services/general-setup-service/customer.service";
import customerTypeService from "@/core/services/general-setup-service/customer-type.service";
import fieldSetService from "@/core/services/fieldset-service/fieldset.service";
import idTemplatesService from "@/core/services/general-setup-service/id-templates.service";
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
import { mapState } from "vuex";
import { Role } from "@/core/services/role.js";

export default Vue.extend({
  name: "client-customers",
  components: {
    // downloadExcel: JsonExcel,
    CreateFilter: () =>
      import(
        /* WebpackChunkName: "CreateFilter" */ "@/view/pages/deposit/filter.vue"
      ),
  },
  data: function () {
    return {
      content_header: {
        title: "Customers",
        subTitle:
          "Set up  with all the details for your organization. Define your institution details and contact information.",
      },
      pagesizes: [
        {
          size: 10,
        },
        {
          size: 20,
        },
        {
          size: 50,
        },
      ],
      json_fields: {
        "Full Name": "full_name",
        Id: "cus_id",
        Branch: "branch",
        "Customer Status ": "cus_status",
        "Account Officer": "acc_off",
        "Total Balance ": "balance",
        "Date Modified": "date_mod",
      },
      pageSize: 10,
      isFullLayout: true,
      isDeleting: false,
      isSaving: false,
      alert: {
        show: false,
        status: "Success",
        title: "Success",
        description: "",
      },
      fetchModel: {
        customerTypeKey: null,
        filters: [],
        pageIndex: 1,
        pageSize: 10,
        customerStatus: null,
        filterCategory: "",
      },
      paging: {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      },
      allCustomers: [],
      customerClientTypes: [],
      allBranches: [],
      allCentres: [],
      customer: null,
      form: {
        assignedBranchKey: null,
        assignedCentreKey: null,
        assignedUserKey: null,
        customerTypesKey: null,
        customerBirthDate: null,
        customerEmailAddress: null,
        customerFirstName: null,
        customerGender: "Male",
        customerHomePhone: null,
        uniqueCustomerId: null,
        bvn: null,
        customerLastName: null,
        customerMiddleName: null,
        customerMobilePhone1: null,
        customerMobilePhone2: null,
        customerNotes: null,
        preferredLanguage: null,
        createdBy: null,
      },
      editForm: {
        customerKey: null,
        assignedBranchKey: null,
        assignedCentreKey: null,
        assignedUserKey: null,
        customerTypesKey: null,
        customerBirthDate: null,
        customerEmailAddress: null,
        customerFirstName: null,
        customerGender: null,
        customerHomePhone: null,
        uniqueCustomerId: null,
        bvn: null,
        customerLastName: null,
        customerMiddleName: null,
        customerMobilePhone1: null,
        customerMobilePhone2: null,
        customerNotes: null,
        preferredLanguage: null,
        customFieldSetFieldsAndValue: [],
        customFieldValues: [],
        identificationDocuments: [],
        selectedIdTemplate: null,
      },
      idTemplates: [],
      customFields: [],
      err: {
        customerKey: false,
        assignedBranchKey: false,
        assignedCentreKey: false,
        assignedUserKey: false,
        customerTypesKey: false,
        customerBirthDate: false,
        customerEmailAddress: false,
        customerFirstName: false,
        customerGender: false,
        customerHomePhone: false,
        uniqueCustomerId: false,
        bvn: false,
        customerLastName: false,
        customerMiddleName: false,
        customerMobilePhone1: false,
        customerMobilePhone2: false,
        customerNotes: false,
        preferredLanguage: false,
      },
      customerStatusForm: {
        customerKey: null,
        customerStatus: null,
      },
      customerStatuses: [
        { val: "PendingApproval", text: "Pending approval" },
        { val: "Inactive", text: "Inactive" },
        { val: "Active", text: "Active" },
        { val: "Exited", text: "Exited" },
        { val: "Blacklisted", text: "Blacklisted" },
        { val: "Rejected ", text: "Rejected " },
      ],
      showFilterModal: false,
      errorCode: "",
      customerError: "",
      exportData: [],
      filterText: "",
      filterEdit: [],
      showEditedValue: 0,
    };
  },
  methods: {
    toggleFilterModal() {
      this.showFilterModal = !this.showFilterModal;
    },
    sendFilter(value) {
      const filterArray = value.map((obj) => {
        const payload = {};

        payload.filterFieldName = obj.filterName;
        payload.filterFieldOperator = obj.filterOp;
        if (obj.value1 === "" || obj.value1 === null) {
          payload.filterFieldValues = [obj.value];
          return payload;
        }
        payload.filterFieldValues = [obj.value, obj.value1];
        return payload;
      });
      this.fetchModel.filters = filterArray;
      this.fetchModel.filterCategory = "customer";
      this.fetchModel.pageIndex = 1;
      this.fetchModel.pageSize = 10;
      this.getFilteredCustomer();
    },
    async getBranches() {
      try {
        this.isLoading = true;
        const res = await branchesService.get({
          branchName: null,
          branchState: "Active",
          pageIndex: 1,
          pageSize: 100,
          sortColumn: "",
          isDescending: false,
          filters: [],
        });
        this.allBranches = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getCentres() {
      try {
        this.isLoading = true;
        const res = await centresService.get({
          centreName: null,
          centreStatus: "Active",
          pageIndex: 1,
          pageSize: 100,
          sortColumn: "",
          isDescending: false,
        });
        this.allCentres = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    getExportData() {
      if (this.paging.totalCount > 0) {
        GeneralApiService.post(`GeneralFilter/ApplyFilter`, {
          filters: this.fetchModel.filters,
          filterCategory: this.fetchModel.filterCategory,
          pageIndex: 1,
          pageSize: this.paging.totalCount,
        })
          .then((res) => {
            this.exportData = res.data.data;
          })
          .catch((e) => {
            const error = e.response.data.message;
            this.alertError(error);
          });
      }
    },
    deleteFilter() {
      this.filterText = "";
      this.filterEdit = [];
    },
    editFilter() {
      this.showEditedValue = Math.random();
      this.toggleFilterModal();
    },
    processDownload() {
      let wb = XLSX.utils.book_new();

      wb.Props = {
        Title: "Customer List Download",
        Subject: "Customer List Excel Sheet",
        Author: "Prolean",
        createdDate: Date.now(),
      };

      wb.SheetNames.push("CustomersList");

      let sheetData = [
        [
          "Full Name",
          "Id",
          "Branch",
          "Customer Status",
          "Account Officer",
          "Total Balance ",
          "Date Modified",
        ],
      ];

      this.exportData.forEach((item) => {
        let new_arr = [];
        new_arr.push(`${item.customerFirstName} ${item.customerLastName}`);
        new_arr.push(item.uniqueCustomerId);
        new_arr.push(item.branchAssigned);
        new_arr.push(item.customerStatus);
        new_arr.push(item.accountOfficer ? item.accountOfficer : "");
        new_arr.push(item.totalBalance | this.numberPipe);
        new_arr.push(
          item.lastModifiedDate ? this.getDate(item.lastModifiedDate) : ""
        );

        sheetData.push(new_arr);
      });

      let ws = XLSX.utils.aoa_to_sheet(sheetData);

      wb.Sheets["CustomersList"] = ws;

      let wbSheet = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      this.saveExcelSheet(this.convertBinaryToOctetStream(wbSheet));
    },

    convertBinaryToOctetStream(sheet) {
      let buf = new ArrayBuffer(sheet.length);
      let view = new Uint8Array(buf);

      for (let i = 0; i < sheet.length; i++) {
        view[i] = sheet.charCodeAt(i) & 0xff;
      }

      return buf;
    },

    saveExcelSheet(data) {
      const url = URL.createObjectURL(
        new Blob([data], { type: "application/vnd.ms-excel" })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "customers.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    pdfDownload() {
      const columns = [
        { title: "Full Name", dataKey: "full_name" },
        { title: "Id", dataKey: "cus_id" },
        { title: "Branch", dataKey: "branch" },
        { title: "Customer Status", dataKey: "cus_status" },
        { title: "Account Officer", dataKey: "acc_off" },
        { title: "Total Balance", dataKey: "balance" },
        { title: "Date Modified", dataKey: "date_mod" },
      ];

      let doc = new jsPDF("l", "pt", "a4");
      doc.setFontSize(6);
      doc.autoTable(columns, this.download);
      doc.save("customers.pdf");
    },
    getCustomerByKey(key) {
      try {
        this.isLoading = true;
        customerService.getCustomerByKey(key).then((res) => {
          this.editForm = res.data.data;
          this.editForm.customerBirthDate = this.getDate(
            this.editForm.customerBirthDate
          );
          this.editForm.identificationDocuments.forEach((x) => {
            x.validUntil = this.getDate(x.validUntil);
          });
          const customFieldValues = this.editForm.customFieldSetFieldsAndValue;
          customFieldValues.forEach((data) => {
            this.customFields.forEach((fields) => {
              if (fields.fieldSetKey === data.customFieldKey) {
                fields.customFields.forEach((res) => {
                  data.fields.forEach((result) => {
                    if (res.customFieldKey === result.fieldKey) {
                      document.getElementById(result.fieldKey).value =
                        result.value;
                    }
                  });
                });
              }
            });
          });
          this.isLoading = false;
        });
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    reloadFilter() {
      this.clearPaging();
      this.deleteFilter();
      this.fetchModel.filters = [];
      this.fetchModel.filterCategory = "";
      this.fetchModel.pageIndex = 1;
      this.fetchModel.pageSize = 10;
      this.getCustomers();
    },
    getCustomers() {
      try {
        this.clearPaging();
        this.isLoading = true;
        customerService
          .getCustomers(this.fetchModel)
          .then((res) => {
            this.allCustomers = res.data.data.items;
            this.paging = res.data.data;
            this.exportData = res.data.data.items;
            this.isLoading = false;
          })
          .catch((e) => {
            this.errorCode = e.response.status;
            const error = e.response.data;
            this.customerError = error;
            this.alertWarning(error);
          });
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getFilteredCustomer() {
      this.clearPaging();
      this.isLoading = true;
      try {
        const res = await GeneralApiService.post(
          `GeneralFilter/ApplyFilter`,
          this.fetchModel
        );
        this.allCustomers = res.data.data;
        this.alertSuccess("Successfully filtered customer");
        this.paging = res.data;
        this.filterText = res.data.filterText.replace(/_/g, " ");
        this.filterEdit = res.data.filters;
        this.isLoading = false;
        this.getExportData();
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getCustomFormFields(type) {
      try {
        this.isLoading = true;
        const model = {
          customFieldSetType: type,
        };
        const res = await fieldSetService.getFormFieldsByType(model);
        this.customFields = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    async getCustomerTypes() {
      try {
        this.isLoading = true;
        const res = await customerTypeService.get({
          filter: {
            mode: "Or",
            filterLines: [],
          },
          pageIndex: 1,
          pageSize: 1000,
          clientType: "Customer",
        });
        this.customerClientTypes = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    editCustomer(item) {
      try {
        this.getCustomFormFields("Customers");
        this.isLoading = true;
        this.customer = item;
        let cdate = this.getDate(item.customerBirthDate);
        this.editForm = {
          customerKey: item.customerKey,
          assignedBranchKey: item.assignedBranchKey,
          assignedCentreKey: item.assignedCentreKey,
          assignedUserKey: item.assignedUserKey,
          customerTypesKey: item.customerTypesKey,
          customerBirthDate: cdate ? cdate : null,
          customerEmailAddress: item.customerEmailAddress,
          customerFirstName: item.customerFirstName,
          customerGender: item.customerGender,
          customerHomePhone: item.customerHomePhone,
          uniqueCustomerId: item.uniqueCustomerId,
          bvn: item.bvn,
          customerLastName: item.customerLastName,
          customerMiddleName: item.customerMiddleName,
          customerMobilePhone1: item.customerMobilePhone1,
          customerMobilePhone2: item.customerMobilePhone2,
          customerNotes: item.customerNotes,
          preferredLanguage: item.preferredLanguage,
        };
        this.isLoading = false;
        this.getCustomerByKey(item.customerKey);
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    captureCustomFieldValues() {
      let fieldValues = [];
      this.customFields.forEach((formSet) => {
        formSet.customFields.forEach((form) => {
          let key = form.customFieldKey;
          let value = document.getElementById(key).value;

          const formValObj = {
            customFieldKey: key,
            value,
          };
          fieldValues.push(formValObj);
        });
      });
      return fieldValues;
    },
    saveEditForm: async function (e) {
      e.preventDefault();
      if (this.validateForm()) {
        try {
          this.isSaving = true;
          this.editForm.customFieldValues = [];
          if (this.customFields.length > 0) {
            this.customFields.forEach((formSet) => {
              formSet.customFields.forEach((form) => {
                let key = form.customFieldKey;
                let value = document.getElementById(key).value;
                const formValObj = {
                  customFieldKey: key,
                  value,
                };
                this.editForm.customFieldValues.push(formValObj);
              });
            });
          }
          await customerService.updateCustomer(
            this.editForm.customerKey,
            this.editForm
          );
          this.isSaving = false;
          document.getElementById("editCustomerModalClose").click();
          this.clearForm();
          this.getCustomers();
          this.alertSuccess("Customer updated.");
        } catch (e) {
          this.isSaving = false;
          const error = e.response.data.data.message;
          this.alertError(error);
        }
      } else {
        this.alertWarning("Some form fields are required.");
      }
    },
    changeStatus(customerKey) {
      this.clearForm();
      try {
        this.isLoading = true;
        customerService.getCustomerByKey(customerKey).then((res) => {
          const customer = res.data.data;
          this.customer = customer;
          this.customerStatusForm.customerKey = customerKey;
          if (customer.customerStatus == "Pending approval") {
            this.customerStatusForm.customerStatus = "PendingApproval";
          } else {
            this.customerStatusForm.customerStatus = customer.customerStatus;
          }
          this.isLoading = false;
        });
      } catch (e) {
        this.isLoading = false;
        const error = e.response.data.message;
        this.alertError(error);
      }
    },
    updateCustomerStatusForm: async function (e) {
      e.preventDefault();
      try {
        this.isSaving = true;
        await customerService.updateStatus(this.customerStatusForm);
        this.isSaving = false;
        document.getElementById("customerStatusModalClose").click();
        this.clearForm();
        this.getCustomers();
        this.alertSuccess("Customer status updated.");
      } catch (e) {
        this.isSaving = false;
        const error = e.response.data.data.message;
        this.alertError(error);
      }
    },
    changePage(pageNum) {
      this.fetchModel.pageIndex = pageNum;
      if (this.fetchModel.filterCategory === "customer") {
        this.getFilteredCustomer();
      } else {
        this.getCustomers();
      }
    },
    changePageSize(pageNum) {
      this.fetchModel.pageSize = pageNum;
      if (this.fetchModel.filterCategory === "customer") {
        this.getFilteredCustomer();
      } else {
        this.getCustomers();
      }
    },
    stepPage(direction) {
      if (direction == "next") {
        this.fetchModel.pageIndex = this.fetchModel.pageIndex + 1;
      }
      if (direction == "previous") {
        this.fetchModel.pageIndex = this.fetchModel.pageIndex - 1;
      }
      if (this.fetchModel.filterCategory === "customer") {
        this.getFilteredCustomer();
      } else {
        this.getCustomers();
      }
    },
    clearPaging() {
      this.paging = {
        pageIndex: 0,
        totalPages: 0,
        pageSize: 0,
        totalCount: 0,
        hasPreviousPage: false,
        hasNextPage: false,
      };
    },
    getDate(s) {
      try {
        let d = new Date(s);
        const yyyy = `${d.getFullYear()}`;
        const mm = `0${d.getMonth() + 1}`.slice(-2);
        const dd = `0${d.getDate()}`.slice(-2);
        return `${yyyy}-${mm}-${dd}`;
      } catch (error) {
        return false;
      }
    },
    alertSuccess(description) {
      this.alert.status = "success";
      this.alert.title = "Success";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertError(description) {
      this.alert.status = "fail";
      this.alert.title = "Error";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    alertWarning(description) {
      this.alert.status = "warn";
      this.alert.title = "Warning";
      this.alert.description = description;
      this.alert.show = true;
      const self = this;
      setTimeout(function () {
        self.alert.show = false;
      }, 15000);
    },
    validateForm() {
      this.clearValidation();
      if (
        this.editForm.customerFirstName &&
        this.editForm.customerLastName &&
        this.editForm.uniqueCustomerId &&
        this.editForm.customerBirthDate &&
        this.editForm.customerGender &&
        this.editForm.customerEmailAddress &&
        this.editForm.assignedBranchKey &&
        this.editForm.assignedCentreKey &&
        this.editForm.assignedUserKey
      ) {
        return true;
      }

      if (!this.editForm.customerFirstName) {
        this.err.customerFirstName = true;
      }
      if (!this.editForm.customerLastName) {
        this.err.customerLastName = true;
      }
      if (!this.editForm.uniqueCustomerId) {
        this.err.uniqueCustomerId = true;
      }
      if (!this.editForm.customerBirthDate) {
        this.err.customerBirthDate = true;
      }
      if (!this.editForm.customerGender) {
        this.err.customerGender = true;
      }
      if (!this.editForm.customerEmailAddress) {
        this.err.customerEmailAddress = true;
      }
      if (!this.editForm.assignedBranchKey) {
        this.err.assignedBranchKey = true;
      }
      if (!this.editForm.assignedCentreKey) {
        this.err.assignedCentreKey = true;
      }
      if (!this.editForm.assignedUserKey) {
        this.err.assignedUserKey = true;
      }

      return false;
    },
    clearValidation() {
      this.err = {
        customerKey: false,
        assignedBranchKey: false,
        assignedCentreKey: false,
        assignedUserKey: false,
        customerTypesKey: false,
        customerBirthDate: false,
        customerEmailAddress: false,
        customerFirstName: false,
        customerGender: false,
        customerHomePhone: false,
        uniqueCustomerId: false,
        bvn: false,
        customerLastName: false,
        customerMiddleName: false,
        customerMobilePhone1: false,
        customerMobilePhone2: false,
        customerNotes: false,
        preferredLanguage: false,
      };
    },
    clearForm() {
      this.customer = null;
      this.editForm = {
        customerKey: null,
        assignedBranchKey: null,
        assignedCentreKey: null,
        assignedUserKey: null,
        customerTypesKey: null,
        customerBirthDate: null,
        customerEmailAddress: null,
        customerFirstName: null,
        customerGender: null,
        customerHomePhone: null,
        uniqueCustomerId: null,
        bvn: null,
        customerLastName: null,
        customerMiddleName: null,
        customerMobilePhone1: null,
        customerMobilePhone2: null,
        customerNotes: null,
        preferredLanguage: null,
        identificationDocuments: [],
        selectedIdTemplate: null,
      };
      this.customerStatusForm = {
        customerKey: null,
        customerStatus: null,
      };
    },
    async getIdTemplates() {
      try {
        this.isLoading = true;
        const res = await idTemplatesService.get();
        this.idTemplates = res.data.data.items;
        this.isLoading = false;
      } catch (e) {
        const error = e.response?.data?.message;
        if (error) return this.alertError(error);
      }
    },
    addIdTemplateToForm() {
      let templateKey = this.editForm.selectedIdTemplate;
      let idtemp = this.idTemplates.find(
        (x) => x.identificationDocumentTemplateKey == templateKey
      );
      if (idtemp != null) {
        let toPush = {};
        Object.assign(toPush, idtemp);
        toPush.documentId = "";
        toPush.validUntil = "";

        this.editForm.identificationDocuments.push(toPush);
      }
    },
    idTemplateRemove(index) {
      this.editForm.identificationDocuments.splice(index, 1);
    },
    uploadFile(index) {
      const formData = new FormData();
      formData.append("uploadFile", this.myFiles);
      this.loading = true;
      ApiService.postFormData(
        `GeneralDocument/UploadAttachmentForCustomer`,
        formData
      )
        .then((resp) => {
          let desc = "File successully uploaded";
          this.editForm.identificationDocuments[index].showDoc = true;
          this.editForm.identificationDocuments[index].filename = "";
          this.editForm.identificationDocuments[index].fileSize = 0;
          this.editForm.identificationDocuments[index].attachments = [
            {
              documentDescription: resp.data.data.documentKey,
              documentFileSize: resp.data.data.fileSize,
              documentName: resp.data.data.fileName,
              documentOriginalFileName: resp.data.data.originalName,
              documentType: resp.data.data.fileType,
              documentLocation: resp.data.data.fileUrl,
            },
          ];
          this.alertSuccess(desc);
          this.loading = false;
          this.filename = "";
          this.fileSize = "";
        })
        .catch((error) => {
          this.alertError(error.response.data.message);
          this.loading = false;
        });
    },
  },
  async created() {
    this.fetchModel.customerStatus = this.$route.params.customerStatus;

    this.getCustomers();
    this.getCentres();
    this.getBranches();
    this.getCustomerTypes();
    this.getIdTemplates();
  },
  filters: {
    numberPipe: function (num) {
      try {
        return `${Number(num).toFixed(2).toLocaleString()}`;
      } catch (error) {
        return `${Number(0).toFixed(2).toLocaleString()}`;
      }
    },
  },
  watch: {
    $route() {
      this.fetchModel.customerStatus = this.$route.params.customerStatus;

      this.getCustomers();
      this.getCentres();
      this.getBranches();
      this.getCustomerTypes();
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    download() {
      let data = [];
      this.exportData.forEach((item) => {
        let new_obj = {};
        new_obj.full_name = `${item.customerFirstName} ${item.customerLastName}`;
        new_obj.cus_id = item.uniqueCustomerId;
        new_obj.cus_status = item.customerStatus;
        new_obj.acc_off = item.accountOfficer ? item.accountOfficer : "";
        new_obj.balance = item.totalBalance | this.numberPipe;
        new_obj.date_mod = item.lastModifiedDate
          ? this.getDate(item.lastModifiedDate)
          : "";
        new_obj.branch = item.branchAssigned;

        data.push(new_obj);
      });

      return data;
    },
    userIsNotAuthorized() {
      return this.errorCode == 409;
    },
    isLargeSizePaged() {
      return this.paging?.totalPages > 10;
    },
    userCanExportToExcel() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = user;
      return permissionsList.CanExportToExcel;
    },

    userCanExportToPDF() {
      const user = this.user.scopes.find(
        (item) => item.scopeName === Role.Documents
      );
      const { permissionsList } = user;
      return permissionsList.CanDownloadDocument;
    },
  },
});
</script>
<style scoped>
.move {
  cursor: move;
}
</style>
